import { axios, axiosRetry } from './axios'

/**
 * @param {string[]} sensorNames
 * @param {Date | undefined} afterDate
 * @param {Date | undefined} beforeDate
 */
const getEventsParams = (sensorNames) => {
  const params = new URLSearchParams()
  params.set('sensors', JSON.stringify(sensorNames))
  return params
}

/**
 * @param {string[]} sensorNames
 * @returns {Promise<{sensors: object[], events: object[], pohinaFactor: number}>}
 */
export const getLatestEvents = async (sensorNames, { retry = false } = {}) => {
  const res = await (retry ? axiosRetry : axios).get('/api/events/latest', {
    params: getEventsParams(sensorNames),
  })
  return res.data
}
