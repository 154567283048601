import React, { useCallback } from 'react'
import cls from 'classnames'
import { useLanguageContext } from '../context'
import './LanguageSwitcher.scss'

export const LanguageSwitcher = ({ className }) => {
  const { lang, setLang } = useLanguageContext()

  const handleSelectChange = useCallback(
    (e) => {
      const val = e.currentTarget.value
      setLang(val)
    },
    [setLang]
  )

  return (
    <div className={cls('language-switcher', className)}>
      <select
        className="language-switcher__select"
        value={lang}
        onChange={handleSelectChange}
      >
        <option value="fi">FI</option>
        <option value="en">EN</option>
      </select>
    </div>
  )
}
