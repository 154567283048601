import React, {
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

const LANG_COOKIE_KEY = 'KLUSTERI_NETWORK_PREFERRED_LANG'

/**
 * @typedef {'fi'|'en'} AppLang
 * @typedef {{lang: AppLang, setLang: (l: AppLang) => void}} LanguageContextType
 * @type {React.Context<LanguageContextType>}
 */
const LanguageContext = React.createContext({
  lang: 'fi',
  setLang: () => {},
})

export const LanguageProvider = ({ children }) => {
  // no SSR so we can set the initial state from the cookie :)
  const [lang, setLang] = useState(
    document.cookie.includes(`${LANG_COOKIE_KEY}=en`) ? 'en' : 'fi'
  )

  const setLangCb = useCallback(
    (lang) => {
      // update state
      setLang(lang)
    },
    [setLang]
  )

  useEffect(() => {
    // persist cookie
    const cookieEntry = `${LANG_COOKIE_KEY}=${lang}`
    const timestamp = new Date(new Date().getTime() + 10 * 365 * 86400 * 1000)
    document.cookie = `${cookieEntry}; expires=${timestamp.toGMTString()}; path=/`

    // set html language, YOLO
    document.documentElement.lang = lang
  }, [lang])

  const value = useMemo(() => ({ lang, setLang: setLangCb }), [lang, setLangCb])

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguageContext = () => useContext(LanguageContext)
