import { useMemo } from 'react'
import dateLocaleFi from 'date-fns/locale/fi'
import dateLocaleEnUS from 'date-fns/locale/en-US'

import { useLanguageContext } from '../context'
import translations from './translations.json'

/**
 * @param {'fi'|'en'} lang
 * @returns {(translationKey: string) => string|undefined}
 */
const makeT = (lang) => (translationKey) => translations[lang]?.[translationKey]

/**
 * @returns {{t: ReturnType<typeof makeT>, dateFnsLocale: import('date-fns').Locale}}
 */
export const useI18n = () => {
  const { lang } = useLanguageContext()

  return useMemo(
    () => ({
      t: makeT(lang),
      dateFnsLocale: lang === 'fi' ? dateLocaleFi : dateLocaleEnUS,
    }),
    [lang]
  )
}
