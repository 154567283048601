// @ts-check
import React, { useState, useEffect } from 'react'
import cls from 'classnames'
import './StaggeredInTransition.scss'

// update scss loop if you need more than 3 children
const HARDCODED_MAX_STAGGER_CHILDREN = 3

const useWarning =
  process.env.NODE_ENV !== 'development'
    ? () => {}
    : (children) => {
        useEffect(() => {
          if (React.Children.count(children) > HARDCODED_MAX_STAGGER_CHILDREN) {
            console.warn(
              'More than HARDCODED_MAX_STAGGER_CHILDREN children passed! Bump up the value in the constant and in the SCSS loop!'
            )
          }
        }, [children])
      }

const StaggeredInTransition = ({
  className = undefined,
  as: Component,
  ...props
}) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true)
    }, 0)
  }, [])

  useWarning(props.children)

  return (
    <Component
      className={cls('staggered-in-transition', className, {
        'staggered-in-transition--mounted': isMounted,
      })}
      {...props}
    />
  )
}

export default StaggeredInTransition
