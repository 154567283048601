// @ts-check
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import dateMax from 'date-fns/max'
import cls from 'classnames'
import dateFormatDistance from 'date-fns/formatDistance'
import subHours from 'date-fns/subHours'
import * as R from 'ramda'
import * as eventsService from '../services/events'
import { getMaintenanceMessage } from '../services/maintenance'
import { useI18n } from '../translations'
import StaggeredInTransition from './StaggeredInTransition'
import './DoorWidget.scss'

// https://stackoverflow.com/a/5650012/996081
const mapRange = (value, low1, high1, low2, high2) => {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)
}

const readableLastUsed = (lastActive, locale) =>
  dateFormatDistance(lastActive, new Date(), {
    locale: locale,
    includeSeconds: true,
  })

const LastActive = ({ lastActive, pohinaFactor }) => {
  const { t, dateFnsLocale } = useI18n()

  const doorsLastUsedText = `${readableLastUsed(lastActive, dateFnsLocale)} ${t(
    'sitten'
  )}`
  const greyscalePercentage = mapRange(
    lastActive.getTime(),
    subHours(lastActive, 4),
    new Date().getTime(),
    0,
    100
  )
  const greyscaleStyle = {
    filter: `grayscale(${Math.round(100 - greyscalePercentage)}%)`,
  }

  return (
    <>
      <p>
        {t('Bmur-aktiivisuutta havaittu')}{' '}
        <span className="door-widget__last-used" style={greyscaleStyle}>
          {doorsLastUsedText}
        </span>
      </p>
      <p>
        {t('Pöhinäkerroin')}{' '}
        <span className="door-widget__pohina-factor">{pohinaFactor}</span>
      </p>
    </>
  )
}

LastActive.propTypes = {
  lastActive: PropTypes.instanceOf(Date).isRequired,
  pohinaFactor: PropTypes.number.isRequired,
}

const ErrorBanner = ({ message }) => (
  <p className="door-widget__error-banner">{message}</p>
)

const MaintenanceBanner = ({ message }) => {
  const { t } = useI18n()

  return (
    <div className="door-widget__maintenance-banner">
      <p className="door-widget__maintenance-title">{t('Huoltoilmoitus')}</p>
      <p className="door-widget__maintenance-message">{message}</p>
    </div>
  )
}

/**
 * Deserializes an event object from the API response into usable objects.
 * Converts snake_case to camelCase and parses ISO format datestamp.
 */
const deserializeEvent = ({ id, sensor_id, type, timestamp }) => ({
  id,
  sensorId: sensor_id,
  type,
  timestamp: new Date(timestamp),
})

const deserializeEvents = R.map(deserializeEvent)
const DATE_EPOCH = new Date(0)

const isClientError = (res) => res.status >= 400 && res.status < 500
const isServerError = (res) => res.status >= 500

const mapAxiosErrorToMessage = (t, err) => {
  if (err.response) {
    if (isClientError(err.response)) {
      return t('Tapahtui virhe. Kokeile ladata sivu uudelleen.')
    }
    if (isServerError(err.response)) {
      return t('Palvelimella tapahtui virhe. Kokeile myöhemmin uudelleen.')
    }
  }

  return t('Tapahtui virhe. Kokeile ladata sivu uudelleen.')
}

const useEventsFetcher = (sensorNames) => {
  const [events, setEvents] = useState([])
  const [pohinaFactor, setPohinaFactor] = useState(0)

  const fetchEvents = useCallback(
    async ({ retry = false } = {}) => {
      const data = await eventsService.getLatestEvents(sensorNames, { retry })
      setEvents(deserializeEvents(data.events))
      setPohinaFactor(data.pohinaFactor)
    },
    [sensorNames]
  )

  return { events, pohinaFactor, fetchEvents }
}

const isDocumentHidden = () => {
  let hiddenProp
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hiddenProp = 'hidden'
  } else if (typeof document['msHidden'] !== 'undefined') {
    hiddenProp = 'msHidden'
  } else if (typeof document['webkitHidden'] !== 'undefined') {
    hiddenProp = 'webkitHidden'
  } else {
    return false
  }

  return document[hiddenProp]
}

const getVisibilityChangeEventName = () => {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange'
  } else if (typeof document['msHidden'] !== 'undefined') {
    return 'msvisibilitychange'
  } else if (typeof document['webkitHidden'] !== 'undefined') {
    return 'webkitvisibilitychange'
  }
  return null
}

const DoorWidget = ({ sensorNames }) => {
  const { t } = useI18n()
  const [isLoading, setLoading] = useState(true)
  const [maintenanceMessage, setMaintenanceMessage] = useState('')
  const [error, setError] = useState('')
  const { events, pohinaFactor, fetchEvents } = useEventsFetcher(sensorNames)

  const fetchMaintenanceMessage = useCallback(async () => {
    const msg = await getMaintenanceMessage()
    setMaintenanceMessage(msg)
  }, [])

  useEffect(() => {
    const doInitialLoad = async () => {
      setLoading(true)
      try {
        await fetchEvents({ retry: true })
        setError('')
      } catch (err) {
        console.error('failed to fetch events', err)
        const errorMessage = mapAxiosErrorToMessage(t, err)
        setError(errorMessage)
      }
      setError('')
      setLoading(false)
    }

    doInitialLoad()
  }, [fetchEvents, t])

  useEffect(() => {
    let timerId = null
    async function fetchEventsLoop() {
      try {
        await fetchEvents()
        setError('')
      } catch (err) {
        console.error('failed to fetch events', err)
      }

      timerId = setTimeout(() => fetchEventsLoop(), 3000)
    }

    const visibilityChange = getVisibilityChangeEventName()
    const documentHiddenHandler = () => {
      if (isDocumentHidden()) {
        clearTimeout(timerId)
        timerId = null
        return
      }

      if (timerId === null) {
        fetchEventsLoop()
      }
    }

    timerId = setTimeout(() => fetchEventsLoop(), 3000)
    if (visibilityChange) {
      document.addEventListener(visibilityChange, documentHiddenHandler, false)
    }

    return () => {
      if (timerId !== null) {
        clearTimeout(timerId)
      }

      if (visibilityChange) {
        document.removeEventListener(
          visibilityChange,
          documentHiddenHandler,
          false
        )
      }
    }
  }, [fetchEvents])

  useEffect(() => {
    fetchMaintenanceMessage()
  }, [fetchMaintenanceMessage])

  const latestActivity = events
    .map((_) => _.timestamp)
    .reduce((acc, val) => dateMax([acc, val]), DATE_EPOCH)

  const canShowWidget = !error && !isLoading

  return (
    <section
      className={cls('door-widget', {
        'door-widget--maintenance': !!maintenanceMessage,
      })}
    >
      {maintenanceMessage && <MaintenanceBanner message={maintenanceMessage} />}
      {error && <ErrorBanner message={error} />}
      {isLoading && <p>{t('Lataa...')}</p>}
      {canShowWidget && (
        <StaggeredInTransition as="div">
          <LastActive lastActive={latestActivity} pohinaFactor={pohinaFactor} />
        </StaggeredInTransition>
      )}
    </section>
  )
}

DoorWidget.propTypes = {
  sensorNames: PropTypes.arrayOf(PropTypes.string),
}

export default DoorWidget
