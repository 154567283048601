import React from 'react'

import DoorWidget from './DoorWidget'
import './App.scss'
import { LanguageProvider } from '../context'
import { LanguageSwitcher } from './LanguageSwitcher'

const PUBLIC_SENSORS = ['klusteri/ac/reed-1', 'klusteri/ac/reed-2']

const App = () => {
  return (
    <LanguageProvider>
      <div className="app">
        <div className="app__container">
          <header className="header app__banner">
            <span>klusteri.network</span>
            <LanguageSwitcher className="header__language-switcher" />
          </header>
          <main>
            <DoorWidget sensorNames={PUBLIC_SENSORS} />
          </main>
        </div>
      </div>
    </LanguageProvider>
  )
}

export default App
