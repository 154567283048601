import Axios from 'axios'
import AxiosRetry from 'axios-retry'

export const axios = Axios.create()

export const axiosRetry = Axios.create()

AxiosRetry(axiosRetry, {
  retries: 3,
  retryDelay: (retryNumber) => AxiosRetry.exponentialDelay(retryNumber + 1),
})
